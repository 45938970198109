// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotate {
    display: inline-block; /* Optional: To ensure proper spacing */
    animation: rotate 2s linear infinite; /* 2s is the duration, change if needed */
  }
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF;;EAEA;IACE,qBAAqB,EAAE,uCAAuC;IAC9D,oCAAoC,EAAE,yCAAyC;EACjF","sourcesContent":["@keyframes rotate {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  \n  .rotate {\n    display: inline-block; /* Optional: To ensure proper spacing */\n    animation: rotate 2s linear infinite; /* 2s is the duration, change if needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
